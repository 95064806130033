import $ from 'jquery'

window.$ = $
window.jQuery = $

// NOTE: bs4とpolyfill.ioの組み合わせで発生するIE11の不具合対策。読み込み順序の関係かここに書かないと上手く効かない。
// SEE: https://github.com/Financial-Times/polyfill-library/issues/164#issuecomment-486977672
if (document.documentMode) {
  const origToString = Object.prototype.toString
  Object.prototype.toString = function () {
    // only necessary if you're not already running everything in strict mode anyohw.
    if (this === null) return '[object Null]'
    return origToString.call(this)
  }
}
